import useRecentHistory from 'Clutch/Hooks/useRecentHistory'
import useAcquisitionType from "Clutch/Hooks/useAcquisition";
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

const sendSegmentCheckFitment = (engineIds, skuData, sendSegmentTrackEventWithName) => {
  let segmentEvent = {
    sku: skuData.skuBaseNumber,
    variant: skuData.skuVariantNumber ? skuData.skuVariantNumber : null,
    engineIds: engineIds
  }
  sendSegmentTrackEventWithName("Unverified Engine Used", segmentEvent);
}

const sendSegmentProductViewed = (data, setMisoId, sendSegmentTrackEventWithName) => {
  try{
    if (!navigator.globalPrivacyControl && data.partNumber != null) {

      //log rocket sessions are tied to this log, don't remove without updating LR first
      console.info("sending Segment Product Viewed event");
      const {list_id, list_name, attribution} = useRecentHistory.GetMostRecentProductListObject()

      let misoId
      try{
        const misoIdData = useRecentHistory.GetAndDeleteMisoId();
        if(misoIdData && misoIdData.skuBase === data.skuBaseNumber){
          misoId = misoIdData.misoId
          setMisoId(misoId)
        }
      }
      catch(err){
        console.error(err)
      }

      let acquisition
      if(!attribution?.acquisition){
        useAcquisitionType((acquisitionType) => {acquisition = acquisitionType})
      }

      let segmentEvent = {
        sku: data.skuBaseNumber,
        variant: data.skuVariantNumber ? data.skuVariantNumber : null, // this will be undefined for sku base pages
        product_id: data.skuBaseNumber,
        name: data.title,
        brand: data.brand?.brandName,
        price: data.retailLowPrice,
        list_id: list_id,
        list_name: list_name,
        quantity: 1, // for viewing a product, qty of 1 seems a good default
        url: data.productPageUrl,
        image_url: (data.images || []).length ? `https:${data.images[0]}` : "",
        category: data.segmentCategorization,
        availability: data.availabilityEnum,
        rating: data.reviewCount ? data.rating : 0,
        reviews: data.reviewCount,
        audience_affinity: getSuperMarketAffinity(data),
        value: data.retailLowPrice, // same as price for qty = 1 // do we still want/need this?
        ...(attribution || {acquisition})
      }

      if(misoId) {
        segmentEvent.miso = { miso_id: misoId }
      }

      sendSegmentTrackEventWithName("Product Viewed", segmentEvent);
    }
  }
  catch(err){
    console.error('There was an error sending the segment product viewed event', err)
  }
};

const trackGoogleRemarketing = (data, retailLowPrice, reportingSkuVariantNumber) => {

  if (window.gtag) {
    window.gtag('event', 'view_item', {
      value: data?.retailLowPrice > 0 ? data?.retailLowPrice : retailLowPrice,
      items: [{
        id: data?.partNumber ? data.reportingSkuVariantNumber : reportingSkuVariantNumber,
        google_business_vertical: 'retail'
      }],
      send_to: "ads",
    })
  }
};

const fbTracking = (sendFacebookEvent, skuVariantNumber, title) => {
  if (typeof skuVariantNumber === "undefined") {
    return;
  }

  sendFacebookEvent({
    type: "track",
    name: "ViewContent",
    params: {
      content_name: title,
      content_ids: [skuVariantNumber],
      content_type: "product",
    },
  });
};

export {fbTracking, trackGoogleRemarketing, sendSegmentProductViewed, sendSegmentCheckFitment}