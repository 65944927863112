'use client'
import createZustandContext from "Utilities/Zustand/createZustandContext";
import { RecommendationProvider } from "Clutch/Stores/RecommendationStore/RecommendationStore";
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import useRecentHistory from 'Clutch/Hooks/useRecentHistory'
import useSegment from "Hooks/useSegment";
import { TrackingContext } from "Contexts/TrackingContext/TrackingContext";
import ProductPageWiring from "./ProductPageWiring"
import { useContext } from "react";
import { fbTracking, trackGoogleRemarketing, sendSegmentProductViewed, sendSegmentCheckFitment } from "../utils/trackingEvents"

const ProductPageContext = createZustandContext(
  ({ set, get, initialProps, props }) => {
    const initialState = {
      ...props.value,
      quantityRequested: 1,
      isIndexOptionErrorState: false,
      isDetailsAccordionOpen: true,
      isCarbAccordionOpen: false,
      isSpecsAccordionOpen: true,
      userToggledSpecsAccordion: false,
      isRatingsAndReviewsAccordionOpen: false,
      isQuestionsAndAnswersAccordionOpen: false,
      isVideosAndArticlesAccordionOpen: false,
      loadingFreeShippingAndAvailability: true,
      loadingShippingEstimate: true,
      loadingIndexOptionSelection: false,
      aPlusContentScrollToLock: { active: false },
      readyForScrollData: { Details: false },
      isFitmentDrawerOpen: false,
      isAdditionalFacetDrawerOpen: false,
      isGarageSaleDrawerOpen: false,
      isCompareToolPresent: true,
      shippingInView: false
    }

    return {
      ...initialProps,
      ...initialState,

      bustStaticPage: (sendFacebookEvent) => {
        import("./fetches/product.js").then((f) => {
          f.bustStaticPage(get().productPageId, get().skuVariantNumber, get()._dependencies.webUserGuid).then(
            (data) => {
              set(x => {
                return {
                  ...x,
                  ...data,
                  isLazyDataHydrated: true
                }
              });

              sendSegmentProductViewed(data, get()._dependencies.setMisoId, get()._dependencies.sendSegmentTrackEventWithName);
              trackGoogleRemarketing(data, get().retailLowPrice, get().reportingSkuVariantNumber);

              if (!navigator.globalPrivacyControl) {
                fbTracking(sendFacebookEvent, get().skuVariantNumber, get().title);
              }
            }
          );
        });
      },

      errorIndexOptions: () => {
        set({ isIndexOptionErrorState: true });
        const element = document.getElementById('pdp_offer');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },

      updateQuantityRequested: (quantity) => {
        get().updatePriceWithPriceBreak(quantity);
        set({ quantityRequested: parseInt(quantity) });
      },

      updatePriceWithPriceBreak: (quantityRequested) => {
        try {
          const { quantityBreaks = [], originalPrice, price, originalSavingsPrice, savingsPrice } = get();

          if (!quantityBreaks.length) {
            set({ needToCalculateQuantityBreak: false });
            return;
          }

          const basePrice = originalPrice?.length ? originalPrice : price;
          const baseSavingsPrice = originalSavingsPrice?.length ? originalSavingsPrice : savingsPrice;

          const quantityBreak = quantityBreaks.findLast(({ priceLevelQuantity }) => quantityRequested >= priceLevelQuantity);

          const newPrice = quantityBreak?.price || basePrice;
          const newSavingsPrice = quantityBreak?.savingsPrice || baseSavingsPrice;

          set({
            originalPrice: basePrice,
            originalSavingsPrice: baseSavingsPrice,
            price: newPrice,
            savingsPrice: newSavingsPrice,
            needToCalculateQuantityBreak: false,
          });
        } catch (error) {
          console.error("Failed to update price", error);
        }
      },

      selectIndexOption: async (productPageId, skuVariant, selectedIndexOptions, mostRecentlySelectedIndexOption, sendFacebookEvent) => {
        import("./fetches/product.js").then((f) => {
          set({ loadingIndexOptionSelection: true });
          f.onIndexOptionSelection(
            productPageId,
            skuVariant,
            selectedIndexOptions,
            mostRecentlySelectedIndexOption,
            get()._dependencies.webUserGuid
          ).then((data) => {

            set((state) => ({
              ...state,
              ...data,
              originalPrice: 0,
              originalSavingsPrice: 0,
              needToCalculateQuantityBreak: true,
              loadingIndexOptionSelection: false
            }));

            if (data.productPageUrl) {
              window.history.replaceState(null, '', `${data.productPageUrl}`)
            }

            useRecentHistory.AddToRecentHistory({ skuBaseNumber: data.skuBaseNumber, url: data.productPageUrl, title: data.title, brand: data.brand?.brandName, segmentCategorization: data.segmentCategorization });
            sendSegmentProductViewed(data, get()._dependencies.setMisoId, get()._dependencies.sendSegmentTrackEventWithName);
            trackGoogleRemarketing(data, get().retailLowPrice, get().reportingSkuVariantNumber);
            fbTracking(sendFacebookEvent, get().skuVariantNumber, get().title);

            if (get().shippingInView) {
              get().getFreeShippingAndAvailability(data.skuVariantNumber);
            }

            get().getLastPurchasedDateForSku(get()._dependencies.webUserGuid, data.skuVariantNumber)
          });
        });
      },

      updatePricesForPromo: (prices) => {
        set(state => {
          return {
            ...state,
            ...prices
          }
        })
      },

      getFreeShippingAndAvailability: (skuVariant) => {
        const productPageId = get().productPageId;
        import("./fetches/product.js").then((f) => {
          f.loadFreeShippingAndAvailability(productPageId, skuVariant).then(
            (data) => {
              if (data && data.freeShippingAndAvailability) {
                set({
                  loadingFreeShippingAndAvailability: false,
                  freeShippingAndAvailability: data.freeShippingAndAvailability,
                });
              }
            }
          );
        });
      },

      scrollTo: (id, accordionName = '', elementInAccordion = false, shouldOpenAccordion = true) => {
        set({
          aPlusContentScrollToLock: {
            active: true,
            id: id,
            accordionName: accordionName,
            waitForAccordionOpen: elementInAccordion
          },
          shouldOpenAccordion: shouldOpenAccordion,
        });
      },

      toggleAccordion: (accordion, isOpen) => set({ [`is${accordion}AccordionOpen`]: isOpen }),

      setIsReadyForScrollData: (state, accordionName) => set({ readyForScrollData: { ...get().readyForScrollData, [accordionName]: state } }),

      getLastPurchasedDateForSku: (webUserGuid, skuVariant) => {
        import("./fetches/checkout.js").then((f) => {
          f.getPurchasedDateForSku(webUserGuid, skuVariant).then(
            (data) => {
              set({
                lastPurchaseDateForSku: data && data.purchasedDateForSku ? data.purchasedDateForSku.orderedDate : null,
              });
            }
          );
        });
      },

      checkFitment: (checkFitmentRequest) => {
        if (checkFitmentRequest && checkFitmentRequest.some(x => 
          x.engineIds && x.engineIds.manufacturerId > 0)){
          console.log("New Engine Interaction")
        }
        import("./fetches/product.js").then((f) => {
          f.checkFitmentNew(
            get().skuBaseNumber,
            get().skuVariantNumber,
            checkFitmentRequest
          ).then((data) => {
            if (data && data.checkFitment) {
              if (data.checkFitment?.fitment?.some(x => x.value?.unverifiedEngineUsed)) {
                const fitments = data.checkFitment.fitment.filter(x => x.value?.unverifiedEngineUsed)
                fitments.forEach (fitment => {
                  const request = checkFitmentRequest.find(x => x.requestIdentifier === fitment.key)
                  const skuData = {
                    skuBaseNumber: get().skuBaseNumber, 
                    skuVariantNumber: get().skuVariantNumber
                  }
                  sendSegmentCheckFitment(request?.engineIds, skuData, get()._dependencies.sendSegmentTrackEventWithName)
                } )
              }
            }
              set(state => {
                return {
                  ...state,
                  ...data.checkFitment,
                }
              });
          });
        });
      },

      getShippingEstimate: (skuVariantNumber, postalCode) => {
        if (get()._dependencies.isBot()) {
          set({ loadingShippingEstimate: false });
          return;
        }

        import("./fetches/checkout.js").then((f) => {
          set({ loadingShippingEstimate: true });
          f.getShippingEstimate(
            skuVariantNumber,
            postalCode,
            get()._dependencies.userVin,
            get()._dependencies.webUserGuid
          ).then((data) => {
            if (data && data.shippingEstimate) {
              set({
                shippingEstimate: {
                  postalCode: postalCode,
                  ...data.shippingEstimate,
                },
                loadingShippingEstimate: false
              });
            }
          });
        });
      },

      setShippingInView: (value) => set({ shippingInView: value }),

      setIsCompareToolPresent: (value) => set({ isCompareToolPresent: value }),

      showFitmentDrawer: () => set({ isFitmentDrawerOpen: true }),
      hideFitmentDrawer: () => set({ isFitmentDrawerOpen: false }),

      showAdditionalFacetDrawer: () => set({ isAdditionalFacetDrawerOpen: true }),
      hideAdditionalFacetDrawer: () => set({ isAdditionalFacetDrawerOpen: false }),

      showGarageSaleDrawer: () => set({ isGarageSaleDrawerOpen: true }),
      hideGarageSaleDrawer: () => set({ isGarageSaleDrawerOpen: false }),

      showWhatsInTheKitDrawer: () => set({ isWhatsInTheKitDrawerOpen: true }),
      hideWhatsInTheKitDrawer: () => set({ isWhatsInTheKitDrawerOpen: false }),

      showInStorePickupDrawer: () => set({ isInStorePickupDrawerOpen: true }),
      hideInStorePickupDrawer: () => set({ isInStorePickupDrawerOpen: false }),
    };
  },
  {
    name: "PdpStore",
  }
);

ProductPageContext.useSynchronizedState = () => {
  const isBot = useUserStore((x) => x.context.isBot);
  const webUserGuid = useUserStore((x) => x.context.userGuid);
  const userVin = useUserStore((x) => x.context.userVin);
  const { sendSegmentTrackEventWithName } = useSegment();
  const { setMisoId } = useContext(TrackingContext);

  return {
    _dependencies: {
      isBot,
      webUserGuid,
      userVin,
      sendSegmentTrackEventWithName,
      setMisoId
    },
  };
};

ProductPageContext.Wiring = ProductPageWiring;

const ProductPageProviderComponent = ({ children, value }) => {
  return (
    <ProductPageContext.Provider value={value}>
      <RecommendationProvider
        sections={["PDP_Top", "PDP_Aside", "PDP_Bottom", "PDP_Kits", "PDP_Articles"]}
        keywords={value?.title}
        skuBaseNumbers={[value?.skuBaseNumber]}
        skuVariantNumbers={value?.skuVariantNumber ? [value.skuVariantNumber] : undefined}
      >
        {children}
      </RecommendationProvider>
    </ProductPageContext.Provider>
  );
};

export const useProductPageStore = ProductPageContext.useStore;
export const ProductPageProvider = ProductPageProviderComponent;